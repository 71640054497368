import styled from 'styled-components';
import DefaultLayout from 'src/components/layouts/default';
import HomeNav from 'src/modules/home/shared/homeNav';
type Props = {
  children: React.ReactNode;
};
export default function HomeLayout({
  children
}: Props): JSX.Element {
  return <DefaultLayout data-sentry-element="DefaultLayout" data-sentry-component="HomeLayout" data-sentry-source-file="home.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="home.tsx">
        <Sidebar data-sentry-element="Sidebar" data-sentry-source-file="home.tsx">
          <HomeNav data-sentry-element="HomeNav" data-sentry-source-file="home.tsx" />
        </Sidebar>
        <Main data-sentry-element="Main" data-sentry-source-file="home.tsx">
          {children}
        </Main>
      </Container>
    </DefaultLayout>;
}
const Container = styled.div`
  display: flex;
  gap: 30px 20px;
  margin-bottom: 120px;

  @media (${({
  theme
}) => theme.underScreenMedium}) {
    margin-bottom: 60px;
  }
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    flex-direction: column;
  }
`;
const Sidebar = styled.div`
  width: 25%;

  @media (${({
  theme
}) => theme.underScreenMedium}) {
    width: 33%;
  }
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    width: auto;
  }
`;
const Main = styled.div`
  width: 75%;

  @media (${({
  theme
}) => theme.underScreenMedium}) {
    width: 67%;
  }
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    width: auto;
    padding: 0 15px;
  }
`;