import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { textLight } from 'styles-js/mixins/typography';
import { useSelector } from 'src/store/store';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import i18n from 'src/utils/translate';
export default function HomeNavUserCard() {
  const router = useRouter();
  const {
    username,
    avatarImageUrl
  } = useSelector(({
    context
  }) => context.currentUser) || {};
  const percentageComplete = useSelector(({
    currentUserProfile
  }) => currentUserProfile.basics.percentageComplete || 0);
  const isNewsfeed = '/newsfeed' === router.pathname;
  const roundedPercent = Math.floor(percentageComplete);
  const onProfileEditClick = () => {
    if (!isNewsfeed) return;
    trackFootfallEvent(Event.Clicked, {
      clickType: 'update-profile',
      clickMetadata: {
        section: Section.NewsFeedProfile
      }
    });
  };
  return <UserCard $isNewsfeed={isNewsfeed} data-sentry-element="UserCard" data-sentry-component="HomeNavUserCard" data-sentry-source-file="homeNavUserCard.tsx">
      <img alt={username + ' profile image'} src={avatarImageUrl} />
      <User data-sentry-element="User" data-sentry-source-file="homeNavUserCard.tsx">
        <Name data-sentry-element="Name" data-sentry-source-file="homeNavUserCard.tsx">{username}</Name>
        {isNewsfeed && roundedPercent < 100 && <ProfileCompletionPercentage>
            {i18n.t(`${roundedPercent}% complete`)}
          </ProfileCompletionPercentage>}
        <Link href="/profile/edit" onClick={onProfileEditClick} data-sentry-element="Link" data-sentry-source-file="homeNavUserCard.tsx">
          {i18n.t('Update profile')}
        </Link>
      </User>
    </UserCard>;
}
const UserCard = styled.div<{
  $isNewsfeed?: boolean;
}>`
  display: flex;
  padding: 15px;
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    ${({
  $isNewsfeed
}) => $isNewsfeed && css`
      display: none;
    `}
  }

  img {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    margin-right: 10px;
  }
`;
const User = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
`;
const Name = styled.div`
  font-size: ${({
  theme
}) => theme.fontSizeM};
  font-weight: ${({
  theme
}) => theme.fontWeightBold};
  line-height: ${({
  theme
}) => theme.fontLineHeightM};
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ProfileCompletionPercentage = styled.span`
  ${textLight};
  margin: 10px 0;
`;