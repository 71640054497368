import { useRouter } from 'next/router';
import styled from 'styled-components';
import Link from 'next/link';
import i18n from 'src/utils/translate';
import HomeNavUserCard from 'src/modules/home/shared/homeNavUserCard';
export default function HomeNav() {
  const router = useRouter();
  return <Wrapper aria-label="my feed navigation" data-testid="home-nav" data-sentry-element="Wrapper" data-sentry-component="HomeNav" data-sentry-source-file="homeNav.tsx">

      <HomeNavUserCard data-sentry-element="HomeNavUserCard" data-sentry-source-file="homeNav.tsx" />

      <Nav data-sentry-element="Nav" data-sentry-source-file="homeNav.tsx">
        <Link href="/newsfeed" data-sentry-element="Link" data-sentry-source-file="homeNav.tsx">
          <Item $isActive={'/newsfeed' === router.pathname} data-sentry-element="Item" data-sentry-source-file="homeNav.tsx">{i18n.t('News Feed')}</Item>
        </Link>
        <Link href="/explore" data-sentry-element="Link" data-sentry-source-file="homeNav.tsx">
          <Item $isActive={'/explore' === router.pathname} data-sentry-element="Item" data-sentry-source-file="homeNav.tsx">{i18n.t('Explore')}</Item>
        </Link>
        <Link href="/followed" data-sentry-element="Link" data-sentry-source-file="homeNav.tsx">
          <DesktopItem $isActive={'/followed' === router.pathname} data-sentry-element="DesktopItem" data-sentry-source-file="homeNav.tsx">{i18n.t('Saved posts')}</DesktopItem>
          <MobileItem $isActive={'/followed' === router.pathname} data-sentry-element="MobileItem" data-sentry-source-file="homeNav.tsx">{i18n.t('Saved')}</MobileItem>
        </Link>
      </Nav>

    </Wrapper>;
}
const Wrapper = styled.div`
  border: ${({
  theme
}) => theme.greyLine};
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    border: none;
    box-shadow: ${({
  theme
}) => theme.shadowLight};
  }
`;
const Nav = styled.nav`
  a {
    display: block;
    border-top: ${({
  theme
}) => theme.greyLine};
  }
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 0 16px;
    display: flex;
    gap: 16px;
    a {
      border-top: none;
    }
  }
`;
const Item = styled.div<{
  $isActive: boolean;
}>`
  padding: 8px 10px;
  border-left: 4px solid;
  color: ${({
  theme
}) => theme.colorBlack};
  font-size: ${({
  theme
}) => theme.fontSizeS};
  line-height: ${({
  theme
}) => theme.fontLineHeightSWide};
  font-weight: ${props => props.$isActive ? props.theme.fontWeightBold : props.theme.fontWeightNormal};
  border-color: ${props => props.$isActive ? props.theme.colorGreen : 'transparent'};

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 6px 0;
    border-left: none;
    border-bottom: 4px solid;
    border-color: ${props => props.$isActive ? props.theme.colorGreen : 'transparent'};
  }
  
  :hover, :focus, :active {
    border-color: ${props => props.$isActive ? props.theme.colorGreen : props.theme.colorGreyLight};
  }
`;
const DesktopItem = styled(Item)`
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    display: none;
  }
`;
const MobileItem = styled(Item)`
  @media (${({
  theme
}) => theme.overScreenSmall}) {
    display: none;
  }
`;